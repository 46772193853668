import React from 'react';
import { Global, css } from '@emotion/react';
import { GatsbyBrowser } from 'gatsby';
import websiteData from './websiteData.json';

let fontsInitialized = false;

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  interface FontData {
    fontFamily: string;
    fontUrl: string;
    isCustom: boolean;
    fontWeight?: string | number;
    fontStyle?: string;
  }

  interface WebsiteData {
    response: {
      data: {
        websiteData: {
          brandbook: {
            fonts: {
              [key: string]: FontData;
            };
          };
        };
      };
    };
  }

  //   const websiteData: WebsiteData = require('./websiteData.json');
  const { fonts } = websiteData.response.data.websiteData.brandbook;

  const customFontsStyles = Object.keys(fonts).map((fontKey: string) => {
    const fontData = fonts[fontKey];
    if (fontData.isCustom) {
      return css`
        @font-face {
          font-family: '${fontData.fontFamily}';
          src: url(${fontData.fontUrl});
          font-weight: ${fontData.fontWeight || 'normal'};
          font-style: ${fontData.fontStyle || 'normal'};
        }
      `;
    }
    return null;
  });

  if (!fontsInitialized && typeof document !== 'undefined') {
    Object.keys(fonts).forEach((fontKey) => {
      const fontData = fonts[fontKey];
      if (!fontData.isCustom && fontData.fontUrl) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = fontData.fontUrl;
        document.head.appendChild(link);
      }
    });

    fontsInitialized = true;
  }

  return (
    <>
      <Global styles={customFontsStyles} />
      {element}
    </>
  );
};
